.products-page-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.products-page-top-content {
  width: 40%;
}
.products-page-top p {
  /* width: 40%; */
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #adadad;
  line-height: 120%;
  letter-spacing: -0.5px;
}
.products-page-first {
  margin-bottom: 140px;
}
.products-page-top h1 {
  font-size: 64px;

  font-weight: 500;
  line-height: 136%;
  letter-spacing: -3px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 120%;
  text-align: left;
  line-height: 120%;
}
.products-page-top h1 span {
  background: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}
.products-page {
  width: 90%;
  margin: 0 auto;
  padding-top: 150px;
}
.products-cards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
}
.products-card {
  width: 60%;
}
.products-cards {
  width: 90%;
  margin-top: 100px;
}
.product-card-img {
  width: 100%;
  cursor: pointer;

  position: relative;
}
.product-card-img-text {
  position: absolute;
  bottom: 30px;
  left: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #fff;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #fff;
  line-height: 116%;
  letter-spacing: -1px;
}
.product-card-img > img {
  width: 100%;
  display: flex;
  height: 500px;
  border-radius: 20px;
  object-fit: cover;
}
.project-card-large {
  width: 100%;
}
.product-card-desc p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #555555;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .products-cards {
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .products-card {
    width: 46%;
  }
  .products-page-top h1 {
    font-size: 48px;
  }
  .products-page-top p {
    font-size: 15px;
  }
}

@media (max-width: 800px) {
  .products-page-top {
    flex-direction: column;
  }
  .products-page-top > div {
    width: 100%;
  }
  .products-page-first{
    margin-bottom: 40px;
  }
  .products-page{
    padding-top: 90px;
  }
  .products-page-top h1 {
    font-size: 36px;
  }
  .products-page-top h1 {
    text-align: left;
    margin-bottom: 20px;
  }
  .product-card-desc p {
    font-size: 15px;
    opacity: 0.7;
  }
  .products-page-top {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .products-card {
    margin-bottom: 30px;
    width: 100%;
  }
}
