.resources {
  padding-top: 140px;
  /*  */
}
.reports {
  background-color: #f5f5f7;
}
.resources > h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  text-align: left;
  line-height: 140%;
  padding: 0 10%;
  text-align: center;
}
.resources > p {
  font-size: 18px;
  max-width: 800px;
  font-weight: 500;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  color: #8a8a8a;
  line-height: 136%;
  letter-spacing: -0.5px;

  text-align: center;
}
.resources-cards {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 80px;
}
.resources-cards > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
.resources-cards > div:nth-child(even) {
  flex-direction: row-reverse;
}
.resources-flex-left {
  overflow: hidden;
  border-radius: 15px;
}

.reources-flex-right {
  width: 40%;
}
.reources-flex-right h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%;
}
.reources-flex-right p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #888888;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin: 15px 0;
}
.reources-flex-right button {
  padding: 12px 26px;
  border: none;
  border-radius: 40px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.reources-flex-right button img {
  width: 20px;
}
.resources-flex {
  margin-bottom: 100px;
}
.report-cards {
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 40px;
  width: 75%;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 90px;
}
.hcai-landing {
  position: relative;
  width: 100%;
}
.hcai-landing img {
  width: 100%;
}
.hcai-content {
  width: 100%;

  position: absolute;
  top: 30%;
  text-align: center;
  color: #fff;
}
.hcai-content h1 {
  font-size: 64px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 140%;
}
.hcai-content p {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #fff;
  opacity: 0.7;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin: 15px 0;
}
.report-card {
  width: 30%;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}
.hcai-flex-item {
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  gap: 40px;
}
.hcai-flex-item img {
  width: 50%;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
}
.hcai-flex-item p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin-top: 20px;
}
.hcai-flex {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 60px;
}
.report-card-img {
  width: 100%;
}
.report-card-img img {
  width: 100%;
}
.report-card-text p {
  text-align: right;
  padding: 13px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.report-card-text p span {
  color: #ababab;
}
.report-card button {
  margin: 0 auto;
  padding: 12px 26px;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 40px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 95%;
  margin-bottom: 13px;
}
.report-card button img {
  width: 13px;
}
.white-papers-right {
  background-color: #fff;
  /* shadow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  height: 600px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.white-papers-left-item {
  display: flex;

  gap: 10px;
}
.white-papers-left-item.active {
  background-color: #fbfbfb;
  /* padding: 12px; */
  border-radius: 8px;
}
.white-papers-left {
  height: 600px;
  overflow-y: scroll;
}
.white-papers-left-item {
  cursor: pointer;
}
.white-papers-left-item p {
  padding-top: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.white-papers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
  padding-bottom: 100px;
}
.white-papers {
  margin-top: 50px;
}
.white-papers-right-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.white-papers-right-text button {
  padding: 12px 26px;
  border: none;
  border-radius: 40px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
  gap: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.white-papers-right-text button img {
  width: 20px;
}
.white-papers-right-text p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -0.5px;
  text-align: right;
}
.white-papers-right-text p span {
  color: #ababab;
  font-size: 16px;
}
@media (max-width: 1300px) {
  .white-papers-left-item img {
    width: 200px;
  }
  .white-papers {
    grid-template-columns: 1fr 0.4fr;
  }
  .white-papers-left-item {
    flex-direction: column;
  }
  .white-papers-left-item {
    width: 210px;
  }
  .white-papers-left-item img {
    width: 100%;
  }
  .white-papers-left-item p {
    padding-right: 10px;
    margin-top: 0;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-top: 0;
  }
  .report-cards {
    width: 90%;
  }

  .hcai-landing img {
    min-height: 700px;
    object-fit: cover;
  }
  .hcai-flex {
    width: 90%;
  }
  .resources-cards {
    width: 90%;
  }
  .hcai-content h1 {
    font-size: 54px;
  }
  .hcai-flex-item img {
    height: 380px;
  }
  .resources-cards > div {
    gap: 20px;
  }
  .hcai-flex-item p {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .white-papers {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .white-papers-right {
    height: 400px;
  }
  .white-papers-left-item {
    min-width: 200px;
    padding: 10px;
  }
  .white-papers-right-text p {
    font-size: 14px;
  }
  .white-papers-right-text button {
    font-size: 14px;
    padding: 8px 20px;
  }
  .white-papers-right-text p span {
    font-size: 13px;
  }
  .white-papers-right-text {
    align-items: unset;
  }
  .white-papers-right-text button {
    width: fit-content;
    justify-content: flex-start;
    text-align: left;
  }
  .white-papers-right-text p {
    width: 100%;
    text-align: left;
    justify-content: flex-end;
  }
  .white-papers-right-text {
    flex-direction: column;
  }
  .white-papers-left-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  .white-papers-left-item {
    display: flex;
    flex-direction: row;
  }
  .white-papers-left-item p {
    font-size: 12px;
  }
  .white-papers-left {
    display: flex;
    flex-direction: row;
    height: unset;
    gap: 20px;
    overflow: scroll;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .report-card {
    width: 45%;
  }
  .report-card-text p {
    font-size: 16px;
  }
  .report-card button {
    padding: 8px 18px;
  }
  .report-cards {
    flex-wrap: wrap;
  }
  .hcai-flex-item {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .hcai-flex-item img {
    width: 100%;
    height: 300px;
  }
  .hcai-flex .hcai-flex-item:nth-child(even) {
    flex-direction: column;
  }
  .resources-cards > div:nth-child(even) {
    flex-direction: column;
  }
  .resources-cards > div {
    flex-direction: column;
  }
  .resources > h1 {
    font-size: 42px;
  }
  .resources > p {
    padding: 0 5%;
  }
  .reources-flex-right {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .report-card {
    width: 100%;
  }
  .hcai-flex-item p {
    font-size: 15px;
    opacity: 0.65;
  }
  .hcai-flex-item img {
    height: 240px;
  }
  .hcai-flex-item {
    margin-bottom: 40px;
  }
  .resources {
    padding-top: 80px;
  }
  .resources > p {
    font-size: 14px;
  }
  .resources > h1 {
    font-size: 36px;
  }
  .resources-flex-left {
    width: 100%;
  }
  .resources-flex-left iframe {
    width: 100%;
    height: 220px;
  }
  .reources-flex-right {
    width: 100%;
  }
  .reources-flex-right h1 {
    font-size: 24px;
  }
  .reources-flex-right p {
    font-size: 14px;
    margin: 10px 0;
  }
  .hcai-content {
    padding: 0 10px;
  }
  .hcai-content p {
    font-size: 18px;
  }
  .hcai-landing img {
    min-height: 400px;
  }
  .hcai-content h1 {
    font-size: 36px;
  }
  .hcai-content h1 br {
    display: none;
  }
  .reources-flex-right button {
    padding: 8px 18px;
    font-size: 14px;
  }
}
