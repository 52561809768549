.main-cards {
  height: 550px;
  border-radius: 20px;
  width: 420px;
}

.main-card-hori {
  height: 450px;
  border-radius: 20px;
  width: 750px;
}
.main-cards > div {
  width: 100%;
}
.card1-image-hori {
  height: 450px;
  border-radius: 20px;
  width: 750px;
}
.card1-image {
  height: 550px;
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
}

.card-content {
  position: relative;
  top: -550px;
  padding: 17px;
  height: 550px;
  width: 100%;
  /* background-color: aqua; */
  z-index: 4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card-content1 {
  position: relative;
  top: -450px;
  padding: 17px;
  height: 450px;
  width: 100%;
  /* background-color: aqua; */
  z-index: 4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card-content-top {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.card-content-bottom {
  width: 100%;
}
.card-content-bottom2 {
  width: 92%;
}
.country-style {
  color: white;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.company-style {
  color: white;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.head-styles {
  height: 30px;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  align-items: center;
  width: fit-content;
  padding: 4px 10px;
  margin-bottom: 10px;
  border-radius: 45px;
  color: white;
  font-size: 12px;
}
.head-styles2 {
  height: 30px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  align-items: center;
  width: 60%;
  border-radius: 45px;
  color: white;
  font-size: 10px;
}

.description-style {
  color: white;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 130%;
}

@media (max-width: 600px) {
  .main-cards {
    height: 550px;
    border-radius: 20px;
    width: 100%;
  }

  .main-card-hori {
    height: 550px;
    border-radius: 20px;
    width: 90%;
  }
  .card1-image-hori {
    height: 550px;
    border-radius: 20px;
    width: 90%;
  }
  .card1 {
    margin-top: 40;
  }
}
