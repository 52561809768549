.contact-page-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px;
}

.contact-page {
  width: 90%;
  margin: 0 auto;
}
.contact-input-item-flex {
  display: flex;
  gap: 20px;
}
.contact-input-item-flex > div:nth-child(1) {
  width: 100px;
}
.contact-input-item select {
  width: 100%;
}

.contact-input-item-flex > div:nth-child(2) {
  width: 80%;
}
.contact-header {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #adadad;
  line-height: 120%;
  letter-spacing: -1.5px;
}
.contact-header p {
  color: #000;
}
.contact-inputs {
  width: 100%;
}
.contact-inputs form {
  width: 100%;
}
.contact-input-item {
  width: 100%;
  margin-top: 50px;
}
.contact-input-item input,
.contact-input-item select {
  width: 100%;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
}
.contact-input-item select option {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.7;
}
.contact-input-item input::placeholder,
.contact-input-item select::placeholder {
  /* text-transform: uppercase;
   */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #ababab;
}

.contact-input-item input:focus {
  outline: none;
}
.contact-input-item input:focus {
  border-bottom: 1px solid #000;
}
.contact-header-flex {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.contact-header-flex img {
  position: relative;
  top: -6px;
}
.contact-header span {
  text-decoration: underline;

  align-items: center;
  gap: 10px;
  background-image: linear-gradient(90deg, #5382ed, #ac6eb3, #d36679);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}
.contact-header span img {
  position: relative;
  top: 1px;
  width: 18px;
}
.contact-inputs button {
  background-color: #000;
  color: #fff;
  padding: 10px 50px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  letter-spacing: -1px;
  margin-top: 40px;

  border-radius: 40px;
}
.contact-page {
  padding-bottom: 80px;
  padding-top: 50px;
}

.contact-page-links-list-item p {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -1px;
  text-align: left;
}
.contact-page-links-list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
}
.contact-page-links-list-item p span {
  color: #ababab;
}
.contact-page-links-list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.partner-wrap {
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-top: 180px;
}
.people-img-bubbles {
  height: 210px;
  margin-bottom: 40px;
}
.people-img-bubbles {
  display: flex;
  align-items: center;
}
.people-img-bubbles-item {
  position: absolute;
}
.people-img-bubbles-item {
  width: 160px;
  overflow: hidden;
  border-radius: 50%;
  height: 160px;
}
.people-img-bubbles-item img {
  width: 160px;
  transition: all 0.5s ease-in-out;
}
.people-img-bubbles-item:hover img {
  transform: scale(1.1);
}
.adithya-bubble {
  left: 10%;
  top: 25%;
}
.aakarsh-bubble {
  left: 25%;
  top: 45%;
}
.shariq-bubble {
  right: 45%;
  top: 55%;
}
.dhairya-bubble {
  right: 25%;
  top: 45%;
}
.atharva-bubble {
  right: 10%;
  top: 35%;
}
.people-page-wrap {
  /* min-height: 90vh; */
  /* height: 750px; */
  margin-bottom: 120px;
}
.atharva-bubble,
.dhairya-bubble {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.atharva-bubble:hover img,
.dhairya-bubble:hover img {
  transform: scale(1.1);
}
.atharva-bubble img,
.dhairya-bubble img {
  width: 130px;
}
.partner-wrap .partner-page {
  text-align: center;
}
.partner-page h1,
.partner-container h1 {
  font-size: 68px;
  font-weight: 500;

  line-height: 120%;
  letter-spacing: -2px;
  margin: 0 auto;
  margin-bottom: 35px;

  font-family: "Poppins", sans-serif;
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  width: fit-content;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.partner-wrap .nabar-cta {
  margin-top: 40px;
}
.partner-container h1 {
  font-size: 54px;
}
.people-img-bubbles-item {
  cursor: pointer;
}
.partner-page p {
  font-size: 20px;
  font-weight: 500;
  max-width: 800px;
  margin: 0 auto;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #828282;
  opacity: 0.8;
  line-height: 136%;
  letter-spacing: -1px;
}
.partner-cta {
  margin-top: 50px;
}
.partner-container {
  margin-top: 70px;
}
.partner-container-wrap {
  background-color: #fcfcfc;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  padding: 80px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}
.partner-card h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%;
}
.partner-card p {
  font-size: 18px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #929292;
  margin-top: 10px;
}
.partner-card-cta button {
  font-size: 16px;
  margin-top: 10px;
}
.people-img-bubbles-item {
  opacity: 0;
  transform: scale(0.6);
  transition: all 0.5s ease-in-out;
}

.people-img-bubbles-item.fade-in {
  opacity: 1;
  transform: scale(1);
}
.people-display-cards-wrap {
  width: 90%;
  margin: 0 auto;
}
.people-display-cards > div {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 140px;
  justify-content: space-between;
  width: 100%;
}
.people-display-card-text > p {
  font-size: 32px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #8a8a8a;
}
.people-display-card-img {
  width: 400px;
}
.people-display-card-text {
  width: 65%;
}
.people-display-card-img img {
  border-radius: 45px;
  width: 100%;
}
.people-display-navigation {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-top: 50px;
}
.people-display-navigation .home-nav-item p span {
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.items {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 14px;
}
.items .pill {
  background-color: #000;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #fff;
}
.people-display-card-text > p span {
  color: #000;
}
.people-display-cards > div:nth-child(even) {
  flex-direction: row-reverse;
}
.people-display-cards > div:nth-child(odd) {
  flex-direction: row;
}
@media (max-width: 1200px) {
  .people-display-card-text > p {
    font-size: 22px;
  }
  .partner-page h1,
  .partner-container h1 {
    font-size: 42px;
  }
  .people-display-card-img {
    width: 340px;
  }
  .people-display-navigation .home-nav-item p {
    font-size: 18px;
  }
  .people-display-navigation .home-nav-item img {
    width: 18px;
  }
  .people-display-cards > div {
    gap: 20px;
  }
  .partner-page p {
    font-size: 18px;
  }
  .client-text h1 {
    font-size: 24px;
  }
  .contact-page-flex {
    flex-direction: column;
  }
  .contact-page-links-list {
    flex-direction: row;
    margin-top: 60px;
  }
  .contact-page-item {
    width: 100%;
  }
  .partner-container-wrap {
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 20px;
  }
  .partner-wrap .nabar-cta {
    margin-top: 20px;
  }
}
@media (max-width: 800px) {
  .contact-header {
    font-size: 28px;
  }
  .partner-container-wrap {
    gap: 20px;
  }
  .partner-container-wrap p {
    font-size: 15px;
    min-height: 108px;
  }

  .partner-card h3 {
    font-size: 28px;
  }

  .people-display-card-text {
    width: 100%;
  }
  .people-display-card-img {
    width: 100%;
  }
  .people-display-card-img img {
    object-fit: cover;
    height: 300px;
    border-radius: 20px;
  }
  .client-text > span {
    font-size: 16px;
  }
  .client-text h1 {
    font-size: 20px;
  }
  .people-display-cards > div {
    margin-bottom: 70px;
  }
  .cleint-img {
    width: 100%;
  }
  .clients .client-card:nth-child(even) {
    flex-direction: column-reverse;
  }
  .cleint-img img {
    object-fit: cover;
    border-radius: 15px;
    height: 380px;
  }
  .client-text {
    width: 100%;
  }
  .people-display-navigation .home-nav-item p {
    font-size: 15px;
  }
  .people-page-wrap {
    margin-top: 100px;
  }
  .partner-page p {
    font-size: 16px;
  }
  .people-display-navigation .home-nav-item img {
    width: 13px;
  }
  .people-display-card-text > p {
    font-size: 18px;
  }
  .clients .client-card {
    flex-direction: column-reverse;
  }
  .people-display-navigation {
    margin-top: 30px;
  }
  .people-display-cards > div:nth-child(odd),
  .people-display-cards > div:nth-child(even) {
    flex-direction: column;
  }
  .contact-page-links-list {
    flex-wrap: wrap;
  }
  .contact-page-links-list-item {
    width: 45%;
  }
  .contact-page-links-list-item p {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .partner-container-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  .partner-card p {
    min-height: unset;
  }
  .partner-card {
    margin-bottom: 30px;
  }
  .partner-wrap{
    margin-top: 100px;
  }
  .partner-page h1{
    margin-bottom: 20px;
  }
  .partner-page p{
    font-size: 15px;
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  .contact-page-links-list-item p {
    font-size: 17px;
  }
  .contact-header {
    font-size: 24px;
  }
  .contact-inputs button {
    font-size: 18px;
  }
  .contact-page-links-list-item {
    width: 40%;
  }
  .contact-page-links-list {
    gap: 20px;
  }
  .contact-input-item {
    margin-top: 30px;
  }
  .contact-input-item input {
    font-size: 14px;
  }
  .contact-input-item input::placeholder {
    font-size: 14px;
  }
  .contact-page-links-list-item img {
    width: 16px;
  }
  .contact-inputs button {
    padding: 8px 25px;
  }
}

@media (min-width: 451px) and (max-width: 600px) {
  .contact-page-links-list {
    /* flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  
}
@media (min-width: 351px) and (max-width: 450px) {
  .contact-page-links-list {
    /* flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  
}
