.internal-pages-landing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 150px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 100px;
}
.internal-pages-left h1 {
  font-size: 54px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;

  line-height: 120%;
  text-align: left;
  line-height: 120%;
}
.internal-pages-left {
  padding-right: 5%;
}
.internal-pages-left h1 span {
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.internal-pages-left p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #a1a1a1;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin: 20px 0;
}
.buttons .secondary {
  background-color: transparent;
  border: none;
  color: #000;
  gap: 5px;
  display: flex;
}
.buttons {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  align-items: center;
}
.buttons button {
  padding: 12px 26px;
  border: none;
  border-radius: 40px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.internal-second-section {
  width: 90%;
  margin: 0 auto;
  margin-top: 70px;
}
.internal-grad-section {
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
  width: 100%;
  padding: 50px 5%;
  text-align: center;
  color: #fff;
}
.internal-pages-right {
  width: 50%;
}
.internal-pages-right img {
  width: 100%;
}
.internal-grad-section h1 {
  font-size: 42px;
  font-weight: 500;
  line-height: 146%;
  letter-spacing: -2px;
  padding: 0 10%;
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 140%;
  margin-bottom: 20px;
}
.internal-pages-left {
  width: 46%;
}

.internal-second-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 100px;
}

.internal-second-section img {
  height: 500px;
  object-fit: cover;
  width: 50%;
  border-radius: 20px;
}
.internal-second-section-content {
  width: 50%;
}
.internal-second-section-content h1 {
  font-size: 42px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%;
  margin-bottom: 20px;
}
.internal-second-section-content h1 span {
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.internal-second-section-content p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #a1a1a1;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin: 20px 0;
}
.impact-second-section {
  width: 90%;
  margin: 0 auto;
  margin-top: 90px;
  margin-bottom: 50px;
}

.impact-second-section-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.impact-second-section-top h1 {
  font-size: 54px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;

  line-height: 120%;
  text-align: left;
  line-height: 120%;
}
.impact-second-section-top h1 span {
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.impact-second-section-top p {
  width: 40%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #a1a1a1;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.impact-second-section-container {
  width: 100%;
  border: 1px solid #dfdfdf;
  background-color: #fcfcfc;
  border-radius: 20px;
  padding: 90px 40px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.impact-second-section-container-item span {
  color: #ececec;
  font-size: 48px;
  font-weight: 500;
}
.impact-second-section-container-item h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  text-align: left;
  line-height: 120%;
}
.impact-second-section-container-item p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #a1a1a1;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin-top: 10px;
}
.impact-navigation {
  width: 90%;
  margin: 50px auto;
  margin-top: 160px;
}
.impact-navigation h1 {
  font-size: 38px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #797979;
  text-align: left;
  line-height: 120%;
}
.about-us-navigation h1 {
  font-size: 34px;
}
.impact-navigation h1 span {
  color: #000;
}
.impact-navigation h1 span span {
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.impact-navigation-items {
  /* display: grid; */
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: repeat(3, 1fr); */
  margin-top: 100px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  gap: 50px;
}

.impact-nav-flex .impact-navigation-item {
  width: unset;
}
.impact-nav-flex {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.impact-navigation-item {
  display: flex;
  /* width: 80%; */
  align-items: center;
  gap: 10px;
}
.impact-navigation-item h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -1px;
  font-family: "Poppins", sans-serif;
  color: #000;
  text-align: left;
  line-height: 120%;
}

.impact-navigation-item h2 span {
  /* background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #a1a1a1;
}
.about-us-navigation h2 span {
  background-image: none;
  color: #a1a1a1;
  -webkit-text-fill-color: unset;
}

@media (max-width: 1200px) {
  .about-team-content h1 {
    font-size: 26px;
  }
  .impact-second-section-top {
    align-items: flex-start;
  }
  .impact-second-section-top p {
    width: 100%;
    margin-top: 20px;
  }
  .internal-second-section {
    width: 90%;
  }
  .about-team-content p {
    font-size: 18px;
  }
  .internal-second-section-content h1 {
    font-size: 36px;
  }
  .internal-second-section-content p {
    font-size: 18px;
  }
  .internal-grad-section h1 {
    font-size: 36px;
    padding: 0;
  }
  .internal-pages-left h1 {
    font-size: 42px;
  }
}
@media (max-width: 1000px) {
  .impact-second-section-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 20px;
  }
  .internal-pages-landing {
    flex-direction: column;
  }
  .impact-second-section-top p {
    font-size: 16px;
  }
  .impact-second-section-top h1 {
    font-size: 46px;
  }
  .impact-second-section-container-item p {
    font-size: 15px;
  }
  .impact-second-section-container-item h1 {
    font-size: 28px;
  }
  .internal-pages-landing > div {
    width: 100%;
  }
  .internal-pages-right img {
    height: 500px;
    object-fit: cover;
    margin-top: 20px;
    border-radius: 20px;
  }
  .internal-second-section {
    flex-direction: column;
  }
  .internal-second-section > div {
    width: 100%;
  }
  .internal-second-section {
    margin-top: 20px;
  }
  .internal-grad-section h1 {
    font-size: 24px;
  }
  .internal-pages-right img {
    height: 320px;
  }
  .internal-pages-left h1 {
    font-size: 33px;
  }
  .internal-pages-left h1 br {
    display: none;
  }
  .internal-second-section-content h1 {
    font-size: 24px;
  }
  .internal-second-section {
    gap: 15px;
  }
  .internal-second-section-content p {
    font-size: 15px;
  }
  .buttons button {
    padding: 8px 20px;
    font-size: 14px;
  }
  .internal-second-section img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
@media (max-width: 800px) {
  .about-team-content h1 {
    font-size: 24px;
  }
  .about-us-navigation h1 {
    font-size: 26px;
    letter-spacing: -1px;
  }
  .team-section img {
    height: 450px;
    object-fit: cover;
  }
  .about-us-team {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .impact-navigation {
    margin-top: 50px;
  }
  .impact-navigation-item h2 {
    font-size: 16px;
  }
  .about-team-content p {
    font-size: 16px;
  }
  .impact-second-section-top h1 {
    font-size: 36px;
  }
  .about-us-navigation h1 {
    font-size: 24px;
  }
  .impact-navigation-items {
    margin-top: 40px;
  }
}
@media (max-width: 600px) {
  .impact-second-section-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 20px;
    gap: 20px;
  }
  .about-us-navigation h1 {
    font-size: 20px;
  }

  .impact-second-section-container-item span {
    font-size: 38px;
  }
}

@media (max-width: 1200px) {
  .internal-second-section {
    width: 90%;
  }
  .internal-second-section-content h1 {
    font-size: 36px;
  }
  .internal-second-section-content p {
    font-size: 18px;
  }
  .internal-grad-section h1 {
    font-size: 36px;
    padding: 0;
  }
  .internal-pages-left h1 {
    font-size: 42px;
  }
 
}
@media (max-width: 1000px) {
  .internal-pages-landing {
    flex-direction: column;
  }
  .internal-pages-landing > div {
    width: 100%;
  }
  .internal-pages-right img {
    height: 500px;
    object-fit: cover;
    margin-top: 20px;
    border-radius: 20px;
  }
  .internal-second-section {
    flex-direction: column;
  }
  .internal-second-section > div {
    width: 100%;
  }
  .internal-second-section {
    margin-top: 20px;
  }
  .internal-grad-section h1 {
    font-size: 24px;
  }
  .internal-pages-right img {
    height: 320px;
  }
  .internal-pages-left h1 {
    font-size: 33px;
  }
  .internal-pages-left h1 br {
    display: none;
  }
  .internal-second-section-content h1 {
    font-size: 24px;
  }
  .internal-second-section {
    gap: 15px;
  }
  .internal-second-section-content p {
    font-size: 15px;
  }
  .buttons button {
    padding: 8px 20px;
    font-size: 14px;
  }
  .internal-second-section img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}


@media (min-width: 601px) and (max-width: 800px) {

  .impact-second-section-container {
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #fcfcfc;
    border-radius: 20px;
    padding: 70px 40px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 60px; */
    row-gap: 10px;
    column-gap: 60px;
  }
  .impact-second-section-top {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .impact-second-section-top h1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #000;
  
    line-height: 120%;
    text-align: left;
    line-height: 120%;
  }
  .impact-second-section-top h1 span {
    background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .impact-second-section-top p {
    width: 90%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #a1a1a1;
    line-height: 136%;
    letter-spacing: -0.5px;
  }
  .impact-navigation h1 {
    font-size: 34px;
    font-weight: 500;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #797979;
    text-align: left;
    line-height: 130%;
  }
  .impact-navigation {
    width: 90%;
    margin: 50px auto;
    margin-top: 80px;
  }
  .impact-navigation-items {
    /* display: grid; */
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: repeat(3, 1fr); */
    margin-top: 80px;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 60px; */
    gap: 50px;
  }
}


@media (min-width: 451px) and (max-width: 600px) {

  .impact-second-section-container {
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #fcfcfc;
    border-radius: 20px;
    padding: 70px 40px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 60px; */
    row-gap: 10px;
    column-gap: 60px;
  }
  .impact-second-section-top {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .impact-second-section-top h1 {
    font-size: 34px;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #000;
  
    line-height: 120%;
    text-align: left;
    
  }
  .impact-second-section-top h1 span {
    background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .impact-second-section-top p {
    width: 90%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #a1a1a1;
    line-height: 136%;
    letter-spacing: -0.5px;
  }
  .impact-navigation h1 {
    font-size: 34px;
    font-weight: 500;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #797979;
    text-align: left;
    line-height: 130%;
  }
  .impact-navigation {
    width: 90%;
    margin: 50px auto;
    margin-top: 80px;
  }
  .impact-navigation-items {
    /* display: grid; */
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: repeat(3, 1fr); */
    margin-top: 80px;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 60px; */
    gap: 50px;
  }
}


@media (min-width: 351px) and (max-width: 450px) {

  .impact-second-section-container {
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #fcfcfc;
    border-radius: 20px;
    padding: 70px 40px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 60px; */
    row-gap: 10px;
    column-gap: 60px;
  }
  .impact-second-section-top {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .impact-second-section-top h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #000;
  
    line-height: 120%;
    text-align: left;
    line-height: 120%;
  }
  .impact-second-section-top h1 span {
    background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .impact-second-section-top p {
    width: 90%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #a1a1a1;
    line-height: 136%;
    letter-spacing: -0.5px;
  }
  .impact-navigation h1 {
    font-size: 34px;
    font-weight: 500;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #797979;
    text-align: left;
    line-height: 130%;
  }
  .impact-navigation {
    width: 90%;
    margin: 50px auto;
    margin-top: 80px;
  }
  .impact-navigation-items {
    display: grid;
    /* display: flex; */
    justify-content: space-between;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 80px;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 60px; */
    gap: 50px;
  }
}


@media (min-width: 280px) and (max-width: 350px) {

  .impact-second-section-container {
    width: 100%;
    border: 1px solid #dfdfdf;
    background-color: #fcfcfc;
    border-radius: 20px;
    padding: 70px 40px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 60px; */
    row-gap: 10px;
    column-gap: 60px;
  }
  .impact-second-section-top {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .impact-second-section-top h1 {
    font-size: 30px;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #000;
  
    line-height: 120%;
    text-align: left;
    line-height: 120%;
  }
  .impact-second-section-top h1 span {
    background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .impact-second-section-top p {
    width: 90%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #a1a1a1;
    line-height: 136%;
    letter-spacing: -0.5px;
  }
  .impact-navigation h1 {
    font-size: 34px;
    font-weight: 500;
    line-height: 136%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #797979;
    text-align: left;
    line-height: 130%;
  }
  .impact-navigation {
    width: 90%;
    margin: 50px auto;
    margin-top: 80px;
  }
  .impact-navigation-items {
    display: grid;
    /* display: flex; */
    justify-content: space-between;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 80px;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 60px; */
    gap: 50px;
  }
  .impact-second-section-container-item h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 146%;
    letter-spacing: -2px;
    font-family: "Poppins", sans-serif;
    color: #000;
    text-align: left;
    line-height: 120%;
  }
}
