.solutions-page {
  padding-top: 150px;
  width: 90%;
  margin: 0 auto;
}
.solutions-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.solutions-header-left p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #adadad;
  line-height: 120%;
  letter-spacing: -1px;
}
.solutions-header-left h1 {
  font-size: 72px;
  margin-top: 15px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -3px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 120%;
  text-align: left;
  line-height: 120%;
}
.solutions-header-left h1 span {
  background-image: linear-gradient(90deg, #5382ed, #d36679, #d36679, #d36679);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.solutions-header-right {
  width: 35%;
}
.solutions-header-right > p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #898989;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin: 20px 0;
}
.solution-img {
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
}
.solution-img img {
  width: 100%;
}
@media (max-width: 1300px) {
  .solutions-header-left h1 {
    font-size: 54px;
  }
  .solutions-header-right {
    width: 45%;
  }
}
@media (max-width: 1000px) {
  .solutions-header-left h1 {
    font-size: 38px;
  }
  .solutions-header-right > h1 br {
    display: none;
  }
  .solutions-header-right .solutions-header-right > p {
    font-size: 16px;
  }
  .solutions-header {
    flex-direction: column;
    align-items: center;
  }
  .solution-img{
    margin-top: 20px;
  }
  .solution-img img{
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
  }
  .solutions-page{
    padding-top: 90px;
  }
  .buttons{
    margin-top: 20px;
  }
  .solutions-header-right > p{
    font-size: 15px;
    opacity: 0.7;
    margin-top: 0;
    width: 100%;
  }
  .solutions-header > div {
    width: 100%;
  }
}
