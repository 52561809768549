.careers-page-header {
  background: linear-gradient(90deg, #5382ed, #d36679, #d36679);
}
.careers-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 120px 5%;
  color: #fff;
}
.careers-page-header h1 {
  font-size: 62px;
  font-weight: 500;

  letter-spacing: -3px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 120%;
  text-align: left;
}
.careers-page-header h1 span {
  font-size: 46px;
  display: flex;
  color: #e6e6e6;
  align-items: center;
  gap: 10px;
}
.careers-page-header h1 span img {
  width: 55px;
}
.careers-links .contact-page-links-list-item p {
  color: #fff;
}

.careers-links .contact-page-links-list-item p span {
  color: #dfdfdf;
}
.carrer-page-wrap {
  width: 90%;
  margin: 0 auto;
  margin-top: 70px;
}
.carrer-page-wrap > p {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #969696;
  line-height: 136%;
  letter-spacing: -1px;
  text-align: left;
}
.carrer-page-wrap h1 {
  font-size: 42px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%;
  margin-bottom: 20px;
}
.career-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
.career-cards > div {
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 20px 0px #0000000d; */
  /* border: 1.2px solid #d2d2d2; */
  background-color: #f5f5f7;
  cursor: pointer;
  transition: 0.3s;
}

.career-cards > div h1 {
  font-size: 28px;
  width: 240px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%;
}
.career-cards {
  margin-top: 70px;
  margin-bottom: 50px;
}
.career-cards > div p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #949494;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin-top: 20px;
}
.career-card button {
  padding: 12px 26px;
  border: none;
  border-radius: 40px;
  background-color: #000;
  width: 100%;
  color: #fff;
  margin-top: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.career-page-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 80px;
}
.sidebar {
  width: 320px;
}

.career-page-container h1 {
  font-size: 36px;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
  color: #000;
  line-height: 110%;
  margin-bottom: 20px;
}
.career-page-container-flex {
  display: flex;
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 90px;
}
.job-container {
  width: 100%;
}
.job-item {
  cursor: pointer;
  padding: 8px;
  border-radius: 10px;
}
.job-item:hover {
  background-color: rgba(242, 242, 242, 0.3);
}
.sidebar-item {
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
}
.sidebar-item p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #949494;
  line-height: 136%;
  letter-spacing: -0.5px;
  padding: 6px;
  padding-right: 40px;
  border-radius: 6px;
}
.job-list p {
}
.sidebar-item.active p {
  color: #000;
}
.sidebar-item.active {
  background-color: #f2f2f2;
}
.job-item {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.job-item-content h2 {
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.job-item-content p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #949494;
  line-height: 136%;
  letter-spacing: -0.5px;
}
.job-item-content {
  width: 60%;
  margin-bottom: 40px;
}
.job-desc-cont h2 {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: 136%;
  letter-spacing: -1px;
}

.job-desc-cont p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #949494;
  line-height: 136%;
  letter-spacing: -0.5px;
  margin-top: 10px;
}
.job-desc-cont p span {
  color: #000;
}

.career-contact {
  width: 100%;
  padding-top: 0;
}
.career-contact .contact-page-flex {
  padding-top: 0;
}
@media (max-width: 1300px) {
  .careers-page-header h1 {
    font-size: 48px;
  }
  .careers-page-header h1 span {
    font-size: 36px;
  }
}
@media (max-width: 1000px) {
  .careers-page-header h1 {
    font-size: 36px;
  }
  .careers-page-header h1 span {
    font-size: 28px;
  }
  .careers-page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .job-item-content {
    width: 100%;
  }
  .job-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 20px;
  }
  .contact-page-links-list-item p {
    font-size: 18px;
  }
  .job-item-content p {
    font-size: 16px;
  }
  .job-item-content h2 {
    font-size: 28px;
  }
  .job-item-content {
    margin-bottom: 10px;
  }
  .careers-page-header h1 span img {
    width: 40px;
  }
}

@media (max-width: 800px) {
  .career-page-container-flex {
    flex-direction: column;
  }
  .careers-links .contact-page-links-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .careers-links .contact-page-links-list-item {
    width: unset;
  }
  .careers-page-header {
    padding: 90px 5%;
    padding-bottom: 40px;
  }
  .careers-page-header h1 {
    font-size: 32px;
  }
  .sidebar > div {
    /* min-width: 120px; */
  }
  .sidebar > div p {
    font-size: 16px;
  }
  .career-page-container {
    margin-top: 30px;
  }
  .career-page-container h1 {
    font-size: 28px;
  }
  .careers-page-header h1 span {
    font-size: 22px;
    letter-spacing: -1px;
  }
  .sidebar {
    width: 100%;
    display: flex;
    overflow: scroll;
    align-items: center;
  }
  .sidebar > div {
    /* min-width: 100px; */
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px;
    padding: 4px 6px;
    border-radius: 8px;
  }
  .job-desc-cont h2 {
    font-size: 24px;
  }
  .job-desc-cont p{
    font-size: 15px;
    opacity: 0.8;
  }
  .sidebar > div p {
    padding: 0;
  }
  .sidebar {
    gap: 10px;
  }
  .sidebar > div p {
    padding-right: 0;
  }
  .job-item-content h2 {
    font-size: 24px;
  }
  .job-item-content p {
    font-size: 14px;
  }
  .job-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(242, 242, 242, 0.8);
  }
}
